import { Button, Typography } from "@mui/material";
import React from "react";
import "./style.js";
import { SideBarContainer, TagsContainer } from "./style.js";
import { TAGS } from "../../data";
import { useLocation, useNavigate } from "react-router-dom";

const SideBar = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { selectedTag } = state ? state : {};
  // console.log(state);

  const handleTagChange = (tag) => {
    navigate("/tags/" + tag, { state: { selectedTag: tag } });
  };

  return (
    <SideBarContainer>
      <Typography
        variant="h5"
        sx={{
          color: "#ff5757",
          bgcolor: "#ddd",
          margin: "2px 10px",
          p: 1,
          borderRight: "4px solid #ff5757",
          fontFamily: `"Caveat", cursive`,
          // borderTopRightRadius: 15,
          // borderBottomRightRadius: 15,
        }}
      >
        Tags
      </Typography>
      <TagsContainer>
        {TAGS.map((tag) => (
          <Button
            sx={{
              width: "47%",
              textTransform: "lowercase",
              backgroundColor: selectedTag === tag.name && "#ff5757",
              color: selectedTag === tag.name && "#000",
              fontWeight: selectedTag === tag.name && "bold",
              "&:hover": {
                backgroundColor: "#ff5757",
              },
            }}
            variant="outlined"
            key={tag.id}
            onClick={() => handleTagChange(tag.name)}
          >
            {tag.name}
          </Button>
        ))}
      </TagsContainer>
    </SideBarContainer>
  );
};

export default SideBar;
