import { styled, Paper } from "@mui/material";

// Blogs
export const BlogsContainer = styled(Paper)(({ theme, bg }) => ({
  // backgroundColor: "red",
  backgroundColor: "#eee",
  maxWidth: "100%",
  minHeight: "70vh",
  maxHeight: "80vh",
  padding: "10px 10px",
  display: "flex",
  flexDirection: "column",
  // alignItems: "center",
  gap: 10,
}));
export const BlogCard = styled(Paper)(({ theme, bg }) => ({
  padding: 8,
}));

export const CardHeading = styled("h3")(({ theme }) => ({
  textAlign: "center",
  fontSize: "2rem",
  fontWeight: "bold",
  "&:hover": {
    cursor: "pointer",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "1.3rem",
  },
}));

export const ShareButton = styled("a")(({ theme, bg }) => ({
  backgroundColor: bg || "#ff5757",
  padding: 4,
  fontSize: "1.2rem",
  marginRight: 5,
  borderRadius: 5,
  color: "white",

  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#ff5757",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: ".8rem",
  },
}));

// Blog
export const BlogContainer = styled(Paper)(({ theme }) => ({
  padding: 20,
  lineHeight: 2,
  fontSize: 13,
  border: "1px solid #ccc",
  // display: "flex",
  // flexDirection: "column",
  // width: "100%",
  // whiteSpace: "initial",
  wordWrap: "break-word",
}));
