import React from "react";
import {
  H1Wrapper,
  IconContainer,
  PortfolioContainer,
  PortfolioHeadingOne,
  PortfolioHeadingTwo,
  ProjectCard,
  ProjectDescription,
  ProjectImage,
  ProjectsContainer,
  ProjectTopBar,
  Tag,
  TagsContainer,
} from "./style";
import { PROJECTS, STACKS } from "../../data";
// import { FiGithub } from "react-icons/fi";
import { BiCode } from "react-icons/bi";
import { IoEyeOutline } from "react-icons/io5";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Loader from "../../components/loader";
// import SEO from "../../layouts/SEO";

const Portfolio = () => {
  const [selectedTag, setSelectedTag] = React.useState(0);
  const [projects, setProjects] = React.useState(PROJECTS);
  const [loading, setLoading] = React.useState(false);
  const [loadedImage, setLoadedImage] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleTagChange = async (id, name) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 300);

    setSelectedTag(id);
    if (name === "All") {
      setProjects(PROJECTS);
      return true;
    }
    const temp = [];
    await PROJECTS.map((project) => {
      // console.log(project.tags);
      // console.log(name);
      if (project.tags.includes(name.toLowerCase())) {
        // console.log(project.id, " has tags");
        temp.push(project);
      }
      return temp;
    });
    setProjects(temp);

    // console.log(projects);
  };
  return (
    <>
      {/* <SEO
        title="Portfolio"
        description="Projects by Daddy Omar Jeng DOJ. Look at some of the projects built by Daddy Omar Jeng, a fullstack software enginner with a lot of experience in the software development field."
        keywords="Projects by Daddy Omar Jeng, Projects by Omar Jeng, DOJ Projects, Daddy Omar Jeng Projects, Omar Jeng Projects, Work by Daddy Omar Jeng DOJ"
        url="https://www.daddyomarjeng.com/portfolio"
      /> */}
      <Loader loading={loading} />

      <Helmet>
        <title>Portfolio | DOJ - Daddy Omar Jeng</title>
        <meta
          name="description"
          content="Projects by Daddy Omar Jeng DOJ. Look at some of the projects built by Daddy Omar Jeng, a fullstack software enginner with a lot of experience in the software development field."
        />
        <meta
          name="keywords"
          content="Projects by Daddy Omar Jeng, Projects by Omar Jeng, DOJ Projects, Daddy Omar Jeng Projects, Omar Jeng Projects, Work by Daddy Omar Jeng DOJ, DOJ, Daddy Omar Jeng, Omar Jeng, Omar, Jeng, Omar Jeng Gambia, DOJ Gambia, Omar Gambia, Jeng Gambia"
        />
        <meta name="author" content="DOJ - Daddy Omar Jeng" />

        <meta property="og:url" content={"https://www.daddyomarjeng.com/portfolio"} />
        <link rel="canonical" href={"https://www.daddyomarjeng.com/portfolio"} />
        <link rel="alternate" href={`${"https://www.daddyomarjeng.com/portfolio"}/`} />
        <meta property="og:image" content={"https://www.daddyomarjeng.com/doj.jpg"} />
      </Helmet>

      <PortfolioContainer>
        <H1Wrapper>
          <PortfolioHeadingOne>My Work</PortfolioHeadingOne>
        </H1Wrapper>
        <PortfolioHeadingTwo>
          Below are some of the projects that I have worked on.
        </PortfolioHeadingTwo>
        <TagsContainer>
          {STACKS.map((stack) => (
            <Tag
              key={stack.id}
              sx={{ color: selectedTag === stack.id ? "#ff5757" : "#888" }}
              onClick={() => handleTagChange(stack.id, stack.name)}
            >
              {stack.name}
            </Tag>
          ))}
        </TagsContainer>
        <ProjectsContainer>
          {projects.map((project, index) => (
            <ProjectCard key={project.id} elevation={5}>
              <ProjectTopBar>
                <Typography ml={1} variant="body1">
                  {project.name}
                </Typography>
                <Box>
                  <IconContainer href={project.code} rel=" noopener noreferrer" target="_blank">
                    <BiCode />
                  </IconContainer>
                  <IconContainer href={project.link} rel=" noopener noreferrer" target="_blank">
                    <IoEyeOutline />
                  </IconContainer>
                  {/* <IconContainer to="#">
                <FiGithub />
              </IconContainer> */}
                </Box>
              </ProjectTopBar>
              <Loader loading={!loadedImage} />
              <ProjectImage
                src={project.img}
                alt={`${project.name} Image`}
                onLoad={() => setLoadedImage(true)}
              />

              <ProjectDescription>{project.description}</ProjectDescription>
            </ProjectCard>
          ))}
        </ProjectsContainer>
      </PortfolioContainer>
    </>
  );
};

export default Portfolio;
