import { Stack, styled } from "@mui/material";

export const SocialConatiner = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: 10,
  position: "absolute",
  top: 100,
  right: 70,
}));

export const SocialLink = styled("a")(({ theme }) => ({
  color: "#555",

  svg: {
    fontSize: 16,

    "&:hover": {
      color: "#ff5757",
    },
  },
}));
