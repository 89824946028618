import React from "react";
import { Helmet } from "react-helmet-async";

const titleSuffix = "DOJ - Daddy Omar Jeng";
const keyWordsSuffix =
  "DOJ, Daddy Omar Jeng, Omar Jeng, Omar, Jeng, Omar Jeng Gambia, DOJ Gambia, Omar Gambia, Jeng Gambia, Full Stack Software Engineer, Mobile App Developer, Gambian Software Engineer, freelancer";
const SEO = ({
  title = `Home`,
  description = "Daddy Omar Jeng(DOJ). Full Stack Software Engineer, Mobile App Developer, BSc Information Systems from University of The Gambia, HND in Computer Science, Gambian Software Engineer, freelancer.",
  keywords = "Omar Jeng",
  author = "DOJ - Daddy Omar Jeng",
  image = "https://www.daddyomarjeng.com/doj.jpg",
  url = "https://www.daddyomarjeng.com",
  charSet = "utf-8",
  themeColor = "#000000",
  viewport = "width=device-width, initial-scale=1",
  favIcon = "https://www.daddyomarjeng.com/favicon.ico",
  manifest = "https://www.daddyomarjeng.com/manifest.json",
}) => (
  <Helmet>
    <link rel="icon" href={favIcon} />
    <link rel="manifest" href={manifest} />

    <meta charSet={charSet} />
    <meta name="viewport" content={viewport} />
    <meta name="theme-color" content={themeColor} />

    <title>
      {title} | {titleSuffix}
    </title>
    <meta property="og:title" content={`${title} | ${titleSuffix}`} />
    <meta property="og:description" content={description} />
    <meta property="og:keywords" content={`${keywords}, ${keyWordsSuffix}`} />
    <meta property="og:author" content={author} />
    <meta property="og:image" content={image} />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={`${title} | ${titleSuffix}`} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />

    <meta property="og:url" content={url} />
    <link rel="canonical" href={url} />
    <link rel="alternate" href={`${url}/`} />
  </Helmet>
);

export default SEO;
