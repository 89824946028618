import { Stack, styled, Typography, Box, Paper } from "@mui/material";

export const PortfolioContainer = styled(Stack)(({ theme }) => ({
  flex: 1,
  minHeight: "70vh",
  padding: "10px 50px",
  position: "relative",
  [theme.breakpoints.down("md")]: { padding: "10px 30px" },
}));

export const PortfolioHeadingOne = styled(Typography)(({ theme }) => ({
  fontSize: "4rem",
  fontWeight: "bold",
  letterSpacing: 0.5,
  color: "#ff5757",
  [theme.breakpoints.down("md")]: {
    fontSize: "2.5rem",
    marginTop: 10,
    // textAlign: "center",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));
export const PortfolioHeadingTwo = styled("pre")(({ theme }) => ({
  fontSize: "1.3rem",
  //   marginTop: "40px",
  whiteSpace: "pre-wrap",
  lineHeight: 2,
  maxWidth: "500px",
  [theme.breakpoints.down("md")]: {
    marginTop: "0px",
    fontSize: "1rem",
  },
}));
export const TagsContainer = styled(Stack)(({ theme }) => ({
  marginTop: 10,
  padding: "5px",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
  width: "80vw",
  overflowX: "auto",
  [theme.breakpoints.down("md")]: {
    marginTop: 0,
  },
}));
export const Tag = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  padding: "0 10px",
  fontSize: "1.3rem",
  "&:hover": {
    color: "#ff5757",
  },
  [theme.breakpoints.down("md")]: {},
}));
export const ProjectsContainer = styled(Stack)(({ theme }) => ({
  //   backgroundColor: "blue",
  width: "80vw",
  overflowX: "auto",
  flexDirection: "row",
  gap: 10,
  padding: "10px 5px",
  position: "relative",
  [theme.breakpoints.down("md")]: {},
}));
export const ProjectCard = styled(Paper)(({ theme }) => ({
  //   backgroundColor: "red",
  minWidth: "400px",
  maxWidth: "500px",
  height: "60vh",
  margin: "20px 10px",
  border: " 2px solid rgb(243, 242, 242)",
  borderRadius: "10px 10px 0px 0px",
  overflow: "hidden",
  // cursor: "pointer",
  position: "relative",

  "&:hover": {
    img: {
      transform: "translateY(-100%)",
    },
  },
  [theme.breakpoints.down("md")]: {
    minWidth: "250px",
    margin: "0 10px",
    height: "50vh",
  },
}));
export const IconContainer = styled("a")(({ theme }) => ({
  //   width: "40px",
  [theme.breakpoints.down("md")]: {},
}));
export const ProjectTopBar = styled(Stack)(({ theme }) => ({
  backgroundColor: "rgb(243, 242, 242)",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  height: "30px",
  zIndex: 10,
  position: "sticky",

  svg: {
    //   backgroundColor: "red",
    color: "#666",
    fontSize: 15,
    width: "22px",
    height: "20px",
    marginRight: 15,

    "&:hover": {
      color: "#ff5757",
    },
  },
  [theme.breakpoints.down("md")]: {},
}));
export const ProjectImage = styled("img")(({ theme }) => ({
  //   height: "80%",
  width: "100%",
  transition: "all 30s ease",
  [theme.breakpoints.down("md")]: {},
}));
export const ProjectDescription = styled(Typography)(({ theme }) => ({
  backgroundColor: "rgb(243, 242, 242)",
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 100,
  textAlign: "center",
  padding: "10px",
  [theme.breakpoints.down("md")]: {},
}));

export const H1Wrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  marginBottom: 10,
  [theme.breakpoints.down("sm")]: {
    marginBottom: 15,
  },

  "&::before": {
    content: `"<h1>"`,
    position: "absolute",
    fontFamily: "La Belle Aurore",
    color: "#ff5757",
    marginTop: "-10px",
    left: "-20px",
  },
  "&::after": {
    content: `"</h1>"`,
    position: "absolute",
    left: "-20px",
    marginTop: "-10px",
    fontFamily: "La Belle Aurore",
    color: "#ff5757",
  },
  [theme.breakpoints.down("md")]: {
    "&::before": {
      left: "-10px",
    },
    "&::after": {
      left: "-10px",
    },
  },
}));
