import { Stack } from "@mui/material";
import React from "react";
import Logo from "../../assets/logo.png";
import Loader from "../loader";
import {
  StyledNavbar,
  StyledNavItem,
  StyledNavItems,
  StyledName,
  StyledLogoBox,
} from "./style.js";

const Navbar = ({ showLogo = true }) => {
  const [loadedImage, setLoadedImage] = React.useState(false);

  return (
    <StyledNavbar>
      {showLogo ? (
        <StyledLogoBox to="/">
          <Loader loading={!loadedImage} />

          <img
            src={Logo}
            alt="DOJ Logo"
            srcSet={Logo}
            height="90"
            onLoad={() => setLoadedImage(true)}
          />
        </StyledLogoBox>
      ) : null}
      <Stack>
        <StyledName className="name">Daddy Omar Jeng</StyledName>
        <StyledNavItems>
          <StyledNavItem
            // exact="true"
            className="nav_item"
            activeclassname="active"
            to="/"
          >
            Home
          </StyledNavItem>
          <StyledNavItem
            // exact="true"
            className="nav_item"
            activeclassname="active"
            to="/about"
          >
            About
          </StyledNavItem>

          <StyledNavItem
            // exact="true"
            className="nav_item"
            activeclassname="active"
            to="/portfolio"
          >
            Portfolio
          </StyledNavItem>

          <StyledNavItem
            // exact="true"
            className="nav_item"
            activeclassname="active"
            to="/contact"
          >
            Contact
          </StyledNavItem>
          <StyledNavItem
            // exact="true"
            className="nav_item"
            activeclassname="active"
            to="/blogs"
          >
            Blogs
          </StyledNavItem>
        </StyledNavItems>
      </Stack>
    </StyledNavbar>
  );
};

export default Navbar;
