import { styled, Box } from "@mui/material";

export const LoaderContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: "100%",
  width: "100%",
  top: 0,
  //   left: 0,
  //   right: 0,
  backgroundColor: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 200,
}));
