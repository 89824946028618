import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, NavLink } from "react-router-dom";
export const StyledNavbar = styled(Stack)(({ theme }) => ({
  //   direction: "row",
  //   alignItems: "center",
  flexDirection: "row",
  justifyContent: "center",
  gap: 60,
  width: "80vw",
  margin: "0 auto",
  className: "navbar",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: 0,
    alignItems: "center",
  },
}));
export const StyledNavItems = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: 20,
  alignItems: "center",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    left: "25px",
  },
  [theme.breakpoints.down("sm")]: { gap: 10 },
}));
export const StyledNavItem = styled(NavLink)(({ theme }) => ({
  color: "#000",
  fontWeight: "bold",
  letterSpacing: "2px",
  fontFamily: `"Caveat", cursive`,
  fontSize: "2.1rem",

  "&.active": {
    color: "#ff5757",
    borderBottom: " 1px solid",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "1.1rem",
  },
}));

export const StyledName = styled(Typography)(({ theme }) => ({
  fontSize: "2.6rem",
  fontWeight: 100,
  fontFamily: '"La Belle Aurore", cursive',
  position: "relative",
  left: "-60px",
  [theme.breakpoints.down("md")]: {
    left: "10px",
  },
}));

export const StyledLogoBox = styled(Link)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    position: "absolute",
    left: 0,
  },
}));
