import { Stack, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/loader";
import { POSTS } from "../../data";
import {
  BlogCard,
  BlogsContainer,
  CardHeading,
  ShareButton,
} from "../blogs/style";

const Tag = () => {
  const [posts, setPosts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const { state } = useLocation();
  const { selectedTag } = state;
  const navigate = useNavigate();
  const base_url = "http://localhost:3000/posts/";
  const whastapp_url = "https://api.whatsapp.com/send?text=From DOJtech Blog: ";

  React.useEffect(() => {
    const temp = [];

    async function test() {
      await POSTS.map((post) => {
        if (post.tags.includes(selectedTag)) {
          temp.push(post);
        }
        return temp;
      });

      setPosts(temp);
    }
    test();
    setLoading(false);

    // console.log(posts);
  }, [selectedTag]);
  return (
    <>
      <Helmet>
        <title>Tags | {selectedTag} | DOJ - Daddy Omar Jeng</title>
        <meta
          name="description"
          content={
            selectedTag +
            " | Blogs by DOJ, Blogs Daddy Omar Jeng. Official Website of Daddy Omar Jeng - DOJ - About Page."
          }
        />
        <meta
          name="keywords"
          content={
            selectedTag +
            ", Blogs by DOJ, Blogs by Daddy Omar Jeng, Blogs by Omar Jeng, DOJ, Daddy Omar Jeng, Omar Jeng, Omar, Jeng, Omar Jeng Gambia, DOJ Gambia, Omar Gambia, Jeng Gambia, Writings by Daddy Omar Jeng DOJ"
          }
        />
        <meta name="author" content="DOJ - Daddy Omar Jeng" />
      </Helmet>

      <Loader loading={loading} />

      <BlogsContainer>
        <Typography
          variant="h2"
          sx={{
            color: "#ff5757",
            bgcolor: "#ddd",
            margin: "2px 20px",
            p: 1,
            borderLeft: "4px solid #ff5757",
            fontFamily: `"Caveat", cursive`,
            borderTopRightRadius: 15,
            borderBottomRightRadius: 15,
          }}
        >
          Tag: <small>{selectedTag}</small>
        </Typography>
        {posts.map((post) => (
          <BlogCard key={post.id} elevation={2}>
            <CardHeading
              onClick={() =>
                navigate(`/posts/${post.slug}`, {
                  state: {
                    id: post.id,
                    slug: post.slug,
                    tags: post.tags,
                    keywords: post.keywords,
                    description: post.description,
                  },
                })
              }
            >
              {post.title}
            </CardHeading>
            <Stack direction="row" alignItems="center" gap={1} mt={5}>
              <Typography sx={{ fontSize: "1rem" }}>{post.date}</Typography>
              <Stack direction="row" alignItems="center">
                <Typography
                  sx={{
                    borderLeft: "3px solid #ff5757",
                    fontSize: "1.3rem",
                    padding: 1,
                    color: "#006",
                  }}
                >
                  Share:
                </Typography>
                <ShareButton
                  bg="#070"
                  href={
                    whastapp_url + post.title + " link: " + base_url + post.slug
                  }
                  data-action="share/whatsapp/share"
                  target="_blank"
                >
                  Whatsapp
                </ShareButton>
                <ShareButton
                  bg="#006"
                  href="#"
                  onClick={() => {
                    navigator.clipboard.writeText(`${base_url}${post.slug}`);
                    window.alert("Copied Text");
                  }}
                >
                  Copy Link
                </ShareButton>
              </Stack>
            </Stack>
          </BlogCard>
        ))}
      </BlogsContainer>
    </>
  );
};

export default Tag;
