import React from "react";
// import { Helmet } from "react-helmet-async";
import "./style.js";
import {
  BodyWrapper,
  ContactButton,
  H1Wrapper,
  HomeContainer,
  HomeHeadingOne,
  HomeHeadingTwo,
  HomeLeft,
  HomeRight,
  HtmlWrapper,
  NameSpan,
  PWrapper,
} from "./style.js";
import Loader from "../../components/loader";
// import SEO from "../../layouts/SEO.jsx";
import { Helmet } from "react-helmet-async";

const HomePage = () => {
  const [loading, setLoading] = React.useState(false);
  const [loadedImage, setLoadedImage] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      <Helmet>
        <link rel="icon" href={"https://www.daddyomarjeng.com/favicon.ico"} />
        <link rel="manifest" href="https://www.daddyomarjeng.com/manifest.json" />

        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />

        <title>DOJ - Daddy Omar Jeng</title>
        <meta property="og:title" content={`DOJ - Daddy Omar Jeng`} />
        <meta
          property="og:description"
          content={
            "Daddy Omar Jeng(DOJ). Full Stack Software Engineer, Mobile App Developer, BSc Information Systems from University of The Gambia, HND in Computer Science, Gambian Software Engineer, freelancer."
          }
        />
        <meta
          property="og:keywords"
          content={`DOJ, Daddy Omar Jeng, Omar Jeng, Omar, Jeng, Omar Jeng Gambia, DOJ Gambia, Omar Gambia, Jeng Gambia, Full Stack Software Engineer, Mobile App Developer, Gambian Software Engineer, freelancer`}
        />
        <meta property="og:author" content={"DOJ - Daddy Omar Jeng"} />
        <meta property="og:image" content={"https://www.daddyomarjeng.com/doj.jpg"} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`DOJ - Daddy Omar Jeng`} />
        <meta
          name="twitter:description"
          content={
            "Daddy Omar Jeng(DOJ). Full Stack Software Engineer, Mobile App Developer, BSc Information Systems from University of The Gambia, HND in Computer Science, Gambian Software Engineer, freelancer."
          }
        />
        <meta name="twitter:image" content={"https://www.daddyomarjeng.com/doj.jpg"} />

        <meta property="og:url" content="https://www.daddyomarjeng.com" />
        <link rel="canonical" href="https://www.daddyomarjeng.com" />
        <link rel="alternate" href="https://www.daddyomarjeng.com/" />
      </Helmet>
      <Loader loading={loading} />
      <HomeContainer>
        <HomeLeft>
          <HtmlWrapper>
            <BodyWrapper>
              <H1Wrapper>
                <HomeHeadingOne>Hi,</HomeHeadingOne>
                <HomeHeadingOne>
                  I'm <NameSpan>Omar Jeng</NameSpan>{" "}
                </HomeHeadingOne>
                <HomeHeadingOne>Software Engineer</HomeHeadingOne>
              </H1Wrapper>
              <PWrapper>
                <HomeHeadingTwo>Mobile App Develope / Web Developer / Freelancer</HomeHeadingTwo>
              </PWrapper>
              <ContactButton to="/contact">CONTACT ME</ContactButton>
            </BodyWrapper>
          </HtmlWrapper>
        </HomeLeft>
        <HomeRight>
          {/* <div className="image"></div> */}
          <Loader loading={!loadedImage} />

          <img
            src={require("../../assets/1.jpg")}
            alt="DOJ PROFILE"
            style={{
              position: "absolute",
              /* left: -10%; */
              height: "80%",
              width: "110%",
              /* border-radius: 50%; */
              /* background:"https://www.daddyomarjeng.com"../images/3.jpg); */
              // backgroundSize: "cover",
              objectFit: "cover",
              filter: "brightness(1.5) contrast(1.5)",
            }}
            onLoad={() => setLoadedImage(true)}
          />
          {/* <div className="circle">
          <div className="text">
            <p>
              I am Daddy Omar Jeng - FullStack Web and Mobile App Developer -
            </p>
          </div>
        </div> */}
        </HomeRight>
      </HomeContainer>
    </>
  );
};

export default HomePage;
