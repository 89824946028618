import { Stack } from "@mui/material";
import { styled } from "@mui/system";
import { NavLink } from "react-router-dom";

export const FooterContainer = styled(Stack)(({ theme }) => ({
  position: "fixed",
  left: 0,
  right: 0,
  bottom: 10,
  width: "100vw",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
  gap: 1,
}));

export const StyledFooterNavItems = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: 20,
  alignItems: "center",
}));
export const StyledFooterNavItem = styled(NavLink)(({ theme }) => ({
  color: "#000",
  //   fontWeight: "bold",
  letterSpacing: "2px",
  fontFamily: `"Caveat", cursive`,
  fontSize: "1.4rem",

  "&:not(:last-child)": {
    borderRight: "1px solid #ff5757",
    paddingRight: 3,
  },

  "&.active": {
    color: "#ff5757",
    borderBottom: " 1px solid",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "1.1rem",
  },
}));
