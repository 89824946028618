import { Stack, styled, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

export const AboutContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  flex: 1,
  minHeight: "70vh",
  padding: "10px 30px",
  gap: 20,
  position: "relative",
  [theme.breakpoints.down("md")]: { padding: "10px 30px" },
}));
export const AboutLeft = styled(Stack)(({ theme }) => ({
  // backgroundColor: "red",
  height: "100%",
  flex: 2,
  position: "relative",
  alignItems: "center",
  // justifyContent: "center",
  // left: 10,
}));
export const AboutRight = styled(Stack)(({ theme }) => ({
  //   backgroundColor: "blue",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  flex: 1,
  position: "relative",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const AboutHeadingOne = styled(Typography)(({ theme }) => ({
  fontSize: "7rem",
  fontWeight: "bold",
  letterSpacing: 0.5,
  color: "#ff5757",
  [theme.breakpoints.down("md")]: {
    fontSize: "4rem",
    textAlign: "center",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "3rem",
  },
}));
export const AboutHeadingTwo = styled("pre")(({ theme }) => ({
  fontSize: "1.3rem",
  marginTop: "40px",
  whiteSpace: "pre-wrap",
  lineHeight: 2,
  maxWidth: "500px",
  [theme.breakpoints.down("md")]: {
    marginTop: "0px",
    fontSize: "1rem",
  },
}));

export const ContactButton = styled(Link)(({ theme }) => ({
  color: "#ff5757",
  fontSize: "2rem",
  fontWeight: 400,
  width: "200px",
  letterSpacing: "4px",
  //   fontFamily: sans-serif,
  padding: "10px 18px",
  border: " 1px solid #ff5757",
  display: "inline-block",
  //   marginTop: "35px",
  position: "relative",
  top: 20,
  [theme.breakpoints.down("md")]: {
    // top: 0,
  },

  "&:hover": {
    backgroundColor: " #ff5757",
    color: "#fff",
  },
}));

export const HtmlWrapper = styled(Stack)(({ theme }) => ({
  flex: 1,
  //   backgroundColor: "red",
  position: "relative",
  fontSize: 20,
  // marginBottom: 20,
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  "&::before": {
    content: `"<html>"`,
    position: "absolute",
    fontFamily: "La Belle Aurore",
    color: "#ff5757",
    top: 0,
    left: "-80px",
  },
  "&::after": {
    content: `"</html>"`,
    position: "absolute",
    bottom: 0,
    left: "-80px",
    fontFamily: "La Belle Aurore",
    color: "#ff5757",
  },
  [theme.breakpoints.down("md")]: {
    "&::before": {
      left: "-30px",
    },
    "&::after": {
      left: "-30px",
    },
  },
}));
export const BodyWrapper = styled(Stack)(({ theme }) => ({
  position: "relative",
  fontSize: 20,
  // marginBottom: 20,
  height: "100%",
  justifyContent: "space-evenly",
  // gap: 30,

  "&::before": {
    content: `"<body>"`,
    position: "absolute",
    top: 30,
    left: "-50px",
    fontFamily: "La Belle Aurore",
    color: "#ff5757",
  },
  "&::after": {
    content: `"</body>"`,
    position: "absolute",
    bottom: 30,
    left: "-50px",
    fontFamily: "La Belle Aurore",
    color: "#ff5757",
  },
  [theme.breakpoints.down("md")]: {
    "&::before": {
      left: "-20px",
    },
    "&::after": {
      left: "-20px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    "&::after": {
      bottom: 50,
    },
  },
}));
export const H1Wrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  fontSize: 20,
  marginBottom: 10,
  [theme.breakpoints.down("sm")]: {
    marginBottom: 50,
  },

  "&::before": {
    content: `"<h1>"`,
    position: "absolute",
    fontFamily: "La Belle Aurore",
    color: "#ff5757",
    marginTop: "-10px",
    left: "-30px",
  },
  "&::after": {
    content: `"</h1>"`,
    position: "absolute",
    left: "-30px",
    marginTop: "-10px",
    fontFamily: "La Belle Aurore",
    color: "#ff5757",
  },
  [theme.breakpoints.down("md")]: {
    "&::before": {
      left: "-10px",
    },
    "&::after": {
      left: "-10px",
    },
  },
}));
export const PWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  fontSize: 20,
  // marginBottom: 20,
  "&::before": {
    content: `"<p>"`,
    position: "absolute",
    fontFamily: "La Belle Aurore",
    color: "#ff5757",
    marginTop: "10px",
    left: "-30px",
  },
  "&::after": {
    content: `"</p>"`,
    position: "absolute",
    left: "-30px",
    marginTop: "-10px",
    fontFamily: "La Belle Aurore",
    color: "#ff5757",
  },
  [theme.breakpoints.down("md")]: {
    "&::before": {
      left: "-10px",
      marginTop: "-30px",
    },
    "&::after": {
      left: "-10px",
    },
  },
}));
