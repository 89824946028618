import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import "./style.css";
// import { hydrate } from "react-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>,

//     root
//   );
// } else {
//   root.render(
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   );
// }

// "reactSnap": {
//   "inlineCss": true
// },
// "postbuild": "react-snap",
