import { Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CardHeading, ShareButton, BlogCard } from "./style";

const BlogCardComponent = ({ post }) => {
  const navigate = useNavigate();

  return (
    <BlogCard elevation={2}>
      <CardHeading
        onClick={() =>
          navigate(`/posts/${post.slug}`, {
            state: {
              id: post.id,
              slug: post.slug,
              tags: post.tags,
              keywords: post.keywords,
              description: post.description,
            },
          })
        }
      >
        {post.title}
      </CardHeading>
      <Stack direction="row" alignItems="center" gap={1} mt={5}>
        <Typography sx={{ fontSize: ".7rem" }}>{post.date}</Typography>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              borderLeft: "3px solid #ff5757",
              fontSize: "1rem",
              padding: 1,
              color: "#006",
            }}
          >
            Share:
          </Typography>
          <ShareButton
            bg="#070"
            href={
              process.env.REACT_APP_WHATSAPP_SHARE +
              post.title +
              " link: " +
              process.env.REACT_APP_BASE_URL +
              "/posts/" +
              post.slug
            }
            data-action="share/whatsapp/share"
            target="_blank"
          >
            Whatsapp
          </ShareButton>
          <ShareButton
            bg="#006"
            href="#"
            onClick={() => {
              navigator.clipboard.writeText(
                `${process.env.REACT_APP_BASE_URL}/posts/${post.slug}`
              );
              window.alert("Copied Text");
            }}
          >
            Copy Link
          </ShareButton>
        </Stack>
      </Stack>
    </BlogCard>
    // <>{JSON.stringify(post)}</>
  );
};

export default BlogCardComponent;
