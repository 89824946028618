import { styled, Stack } from "@mui/material";

export const SideBarContainer = styled(Stack)(({ theme }) => ({
  //   backgroundColor: "red",
  padding: "10px",
  height: "70vh",
  maxHeight: "70vh",
  overflow: "auto",
  position: "sticky",
  top: 100,

  //   borderRight: "2px solid #eee",
  [theme.breakpoints.down("md")]: {
    // display: "none",
    flexDirection: "row",
    alignItems: "center",
    width: "90%",
    height: "100%",
  },
}));

export const TagsContainer = styled(Stack)(({ theme }) => ({
  marginTop: 10,
  flexDirection: "row",
  alignItems: "center",
  gap: "5%",
  flexWrap: "wrap",
  //   borderRight: "2px solid #eee",
  [theme.breakpoints.down("md")]: {
    // display: "none",
    flexWrap: "nowrap",
    marginTop: 0,
  },
}));
