import { Stack, styled, Typography } from "@mui/material";

export const ContactContainer = styled(Stack)(({ theme }) => ({
  height: "75vh",
  position: "relative",

  [theme.breakpoints.down("md")]: {
    marginTop: 35,
    height: "70vh",
  },
}));
export const SideBg = styled(Stack)(({ theme }) => ({
  height: "100%",
  width: "20px",
  position: "absolute",
  left: 10,
  backgroundColor: "#ff5757",
}));

export const ContactHeadingOne = styled(Typography)(({ theme }) => ({
  fontSize: "6rem",
  width: "80%",
  // fontWeight: "bold",
  letterSpacing: 0.8,
  color: "#ff5757",
  [theme.breakpoints.down("md")]: {
    fontSize: "2.5rem",
    marginTop: 10,
    // textAlign: "center",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));
