import { Box, Divider, Stack } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer/index.jsx";
import Navbar from "../components/navbar/index.jsx";
import { BlogOutletContainer } from "./style.js";
import RightBar from "../components/rightbar/index.jsx";
import SideBar from "../components/sidebar/index.jsx";
// import SocialIcons from "../components/socialIcons/index.jsx";

const BlogLayout = () => {
  return (
    <Box mt={2} sx={{ maxHeight: "90vh", overflowY: "auto" }}>
      <Navbar showLogo={false} />
      <Stack gap={3} sx={{ mt: 6, flexDirection: { sm: "column", md: "row" } }}>
        <Stack flex={1}>
          <SideBar />
        </Stack>
        <BlogOutletContainer flex={3}>
          <Outlet />
        </BlogOutletContainer>
        <Stack flex={1}>
          <RightBar />
        </Stack>
      </Stack>
      <Divider
        color="#ff5757"
        sx={{
          backgroundColor: "#ff5757",
          position: "fixed",
          bottom: 40,
          left: 0,
          right: 0,
        }}
      />{" "}
      <Footer />
      {/* <SocialIcons /> */}
    </Box>
  );
};

export default BlogLayout;
