import React, { useRef, useState } from "react";
import "./style.css";
import Phone from "../../assets/phone.png";
import Email from "../../assets/email.png";
import Address from "../../assets/address.png";
import { ContactContainer, ContactHeadingOne, SideBg } from "./style";

import emailjs from "@emailjs/browser";
import Loader from "../../components/loader";
import SEO from "../../layouts/SEO";

function Contact() {
  const formRef = useRef();
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        "service_7ipadsc",
        "template_38gicuf",
        formRef.current,
        "user_1zwGPsjXm34lLSouSpLfA"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
          setLoading(false);
        },
        (error) => {
          alert(
            "Sorry, email server down. Please contact me using the email provided above, or reach out to me on any of my social medias on top"
          );
          console.log(error.text);
          setLoading(false);
        }
      );
  };
  return (
    <>
      <SEO
        title="Contact"
        description="Contact DOJ, Contact Daddy Omar Jeng. Do you want a developer to build  your project or consultant on a to help you build your project? Reach out to Daddy Omar Jeng, a fullstack software enginner with a lot of experience in the software development field."
        keywords="Contact DOJ, Contact Daddy Omar Jeng, Reach out to Daddy Omar Jeng, Get in Touch with Daddy Omar Jeng."
        url="https://www.daddyomarjeng.com/contact"
      />

      <Loader loading={loading} />
      <ContactContainer>
        <SideBg />
        <div className="contact-wrapper">
          <div className="contact-left">
            <ContactHeadingOne>Let's discuss your project</ContactHeadingOne>
            <div className="contact-info">
              <div className="contact-info-item">
                <img src={Phone} alt="" className="contact-icon" />+ 220 6859 227 / +220 9014 359
              </div>
              <div className="contact-info-item">
                <img src={Email} alt="" className="contact-icon" />
                daddyomarjeng1@gmail.com
              </div>
              <div className="contact-info-item">
                <img src={Address} alt="" className="contact-icon" />
                Banjulinding Village
              </div>
            </div>
          </div>
          <div className="contact-right">
            <p className="contact-desc">
              <b>Want to reach out to me?</b> Get in touch. Always available for freelancing if the
              right project comes along.
            </p>

            <form ref={formRef} onSubmit={handleSubmit}>
              <input required type="text" placeholder="Name" name="user_name" />
              <input required type="text" placeholder="subject" name="user_subject" />
              <input required type="text" placeholder="Email" name="user_email" />
              <textarea required name="message" rows="3" placeholder="Message" />
              <button>Submit</button>
              {done && "Thank you......"}
            </form>
          </div>
        </div>
      </ContactContainer>
    </>
  );
}

export default Contact;
