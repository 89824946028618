import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { BlogContainer } from "./style";
import { useLocation, useNavigate } from "react-router-dom";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
// import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Button, Stack, Typography } from "@mui/material";
import Loader from "../../components/loader";
import Toc from "react-toc";
import SEO from "../../layouts/SEO";
const Blog = () => {
  // const file_name = "express_js_basics.md";
  const [post, setPost] = useState("loading");
  const [loading, setLoading] = React.useState(true);

  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  // console.log(useLocation());

  const { slug, tags, keywords, description } = state ? state : {};
  // console.log(slug);

  useEffect(() => {
    import(`./posts/${slug ? slug : pathname.split("posts/")[1]}.md`)
      .then((res) => {
        // console.log(res);

        fetch(res.default)
          .then((res) => res.text())
          .then((res) => {
            setPost(res);
            console.log(res);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        // console.log(err);
        navigate(-1);
      });

    setLoading(false);
  }, [slug, navigate, pathname]);

  return (
    <>
      <SEO
        title={`Blogs ${slug ? "| " + slug : ""}`}
        description={`Blogs by DOJ, Blogs Daddy Omar Jeng. ${
          description ? description : ""
        } Checkout blogs and articles written by Daddy Omar Jeng, a fullstack software enginner with a lot of experience in the software development field.`}
        keywords={`${
          keywords ? keywords : "DOJ"
        }, Blogs by DOJ, Blogs by Daddy Omar Jeng, Blogs by Omar Jeng, Articles by Daddy Omar Jeng`}
        url={`https://www.daddyomarjeng.com/blogs/${slug}`}
      />

      <Loader loading={loading} />
      <Stack
        direction="row"
        gap={2}
        mb={3}
        // ml={2}
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%", overflowX: "auto" }}
      >
        <Typography color="#ff5757" fontWeight="bold" fontSize={15}>
          {tags ? "Related Tags:" : ""}
        </Typography>
        {tags?.map((tag, i) => (
          <Button
            variant="outlined"
            key={i}
            onClick={() => navigate("/tags/" + tag, { state: { selectedTag: tag } })}
          >
            {tag}
          </Button>
        ))}
      </Stack>
      <BlogContainer elevation={3}>
        <Toc markdownText={post} />

        <ReactMarkdown
          children={post}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <SyntaxHighlighter
                  children={String(children).replace(/\n$/, "")}
                  style={dark}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                />
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
          }}
        />
      </BlogContainer>
    </>
  );
};

// const Component = ({ value, language }) => {
//   // const codeString = "---(num) => num + 1---";
//   return (
//     // ?? means if null
//     <SyntaxHighlighter language={language ?? null} style={docco}>
//       {/* {codeString} */}
//       {value ?? ""}
//     </SyntaxHighlighter>
//   );
// };

export default Blog;
