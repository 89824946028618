// BlogLayout

import { Stack, styled } from "@mui/material";

export const BlogOutletContainer = styled(Stack)(({ theme }) => ({
  //   backgroundColor: "blue",
  margin: "0 auto",
  padding: "10px",
  minWidth: "300px",
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "300px",
  },
}));
