import { Typography } from "@mui/material";
import React from "react";
import { POSTS } from "../../data";
import { BlogsContainer } from "./style";
import Loader from "../../components/loader";
import BlogCardComponent from "./BlogCard";
import SEO from "../../layouts/SEO";
// import fs from "fs";
// const matter = require("gray-matter");
const Blogs = () => {
  const [posts, setPosts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const gett = async () => {
      try {
        const getPosts = POSTS;
        setPosts(getPosts);
      } catch (error) {
        console.log(error);
      }
    };
    gett();
    setLoading(false);
    // console.log(posts);
  }, []);
  return (
    <>
      <SEO
        title="Blogs"
        description="Blogs by DOJ, Blogs Daddy Omar Jeng. Checkout blogs and articles written by Daddy Omar Jeng,  a fullstack software enginner with a lot of experience in the software development field."
        keywords="Blogs by DOJ, Blogs by Daddy Omar Jeng, Blogs by Omar Jeng, Articles by Daddy Omar Jeng"
        url="https://www.daddyomarjeng.com/blogs"
      />
      <Loader loading={loading} />

      <BlogsContainer elevation={5}>
        <Typography
          variant="h2"
          sx={{
            color: "#ff5757",
            bgcolor: "#ddd",
            margin: "2px 20px",
            p: 1,
            borderLeft: "4px solid #ff5757",
            fontFamily: `"Caveat", cursive`,
            borderTopRightRadius: 15,
            borderBottomRightRadius: 15,
          }}
        >
          Blogs
        </Typography>
        {posts.map((postData) => (
          // <h1>{post.title}</h1>
          <BlogCardComponent post={postData} key={postData.id} />
        ))}
      </BlogsContainer>
    </>
  );
};

export default Blogs;
