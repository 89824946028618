import { HelmetProvider } from "react-helmet-async";
import { Route, Routes } from "react-router-dom";
import BlogLayout from "./layouts/BlogLayout";
import MainLayout from "./layouts/MainLayout";
import AboutPage from "./pages/about";
import Blog from "./pages/blogs/blog";
import Blogs from "./pages/blogs/blogs";
import Contact from "./pages/contact";
import HomePage from "./pages/home";
import Portfolio from "./pages/portfolio";
import Tags from "./pages/tags";
import Tag from "./pages/tags/Tag";
import "./style.css";
function App() {
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<Contact />} />
        </Route>
        <Route path="/" element={<BlogLayout />}>
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/posts/:id" element={<Blog />} />
          <Route path="/tags" element={<Tags />} />
          <Route path="/tags/:tag" element={<Tag />} />
        </Route>
      </Routes>
    </HelmetProvider>
  );
}

export default App;
