import React from "react";
import "./style.js";
import { RightbarContainer } from "./style";
import { Card, Paper, Stack, Typography } from "@mui/material";

const RightBar = () => {
  return (
    <RightbarContainer>
      <Stack>
        <Typography
          variant="h5"
          sx={{
            color: "#ff5757",
            bgcolor: "#ddd",
            margin: "2px 10px",
            p: 1,
            borderLeft: "4px solid #ff5757",
            fontFamily: `"Caveat", cursive`,
            // borderTopRightRadius: 15,
            // borderBottomRightRadius: 15,
          }}
        >
          Latest Posts
        </Typography>
      </Stack>
      <Paper
        elevation={3}
        sx={{
          pb: 3,
          maxWidth: "300px",
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={() =>
          window.open(
            "https://all-in-one-stationery-stationery-store.business.site/?utm_source=gmb&utm_medium=referral"
          )
        }
      >
        <Card
          variant="outlined"
          sx={{ backgroundColor: "#ccc", fontSize: 12, textAlign: "center" }}
        >
          All In One Stationery
        </Card>
        <img
          src={require("../../assets/aio.png")}
          // width="%"
          height="100px"
          alt=""
          srcSet=""
        />
        <Typography variant="body2" p={1}>
          For all your Stationery needs, <br />
          Country: The Gambia, <br />
          Address: OldJeshwang, <br />
          Contact:+2203540237
        </Typography>
      </Paper>
      <Stack>
        <Typography
          variant="h5"
          sx={{
            color: "#ff5757",
            bgcolor: "#ddd",
            margin: "2px 10px",
            p: 1,
            borderLeft: "4px solid #ff5757",
            fontFamily: `"Caveat", cursive`,
            // borderTopRightRadius: 15,
            // borderBottomRightRadius: 15,
          }}
        >
          Popular Posts
        </Typography>
      </Stack>
    </RightbarContainer>
  );
};

export default RightBar;
