import React from "react";
import { SocialConatiner, SocialLink } from "./style";
import { FaLinkedinIn, FaGithub, FaInstagram, FaTwitter } from "react-icons/fa";

const SocialIcons = () => {
  return (
    <SocialConatiner>
      <SocialLink
        href="https://www.linkedin.com/in/daddyomarjeng"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaLinkedinIn />
      </SocialLink>
      <SocialLink href="https://github.com/daddyomarjeng" target="_blank" rel="noopener noreferrer">
        <FaGithub />
      </SocialLink>
      <SocialLink
        href="https://www.instagram.com/doj_official/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaInstagram />
      </SocialLink>
      <SocialLink
        href="https://www.twitter.com/doj_official/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaTwitter />
      </SocialLink>
      {/* <SocialLink
        href="https://www.facebook.com/daddyomarjeng1"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebook />
      </SocialLink>
      <SocialLink
        href="https://www.youtube.com/channel/UCvnzB3KfpEa7JrSRnP22MuQ"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaYoutube />
      </SocialLink> */}
    </SocialConatiner>
  );
};

export default SocialIcons;
