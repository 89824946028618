import { Stack } from "@mui/material";
import React from "react";
import {
  FooterContainer,
  StyledFooterNavItem,
  StyledFooterNavItems,
} from "./style.js";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
const Footer = () => {
  return (
    <FooterContainer>
      <StyledFooterNavItems
        sx={{
          gap: "5px",
          position: "relative",
          left: { sm: "25px" },
        }}
      >
        <StyledFooterNavItem
          className="nav_item"
          activeclassname="active"
          to="/"
        >
          Home
        </StyledFooterNavItem>
        <StyledFooterNavItem
          className="nav_item"
          activeclassname="active"
          to="/about"
        >
          About
        </StyledFooterNavItem>
        <StyledFooterNavItem
          className="nav_item"
          activeclassname="active"
          to="/portfolio"
        >
          Portfolio
        </StyledFooterNavItem>

        <StyledFooterNavItem
          className="nav_item"
          activeclassname="active"
          to="/contact"
        >
          Contact
        </StyledFooterNavItem>
        <StyledFooterNavItem
          className="nav_item"
          activeclassname="active"
          to="/blogs"
        >
          Blogs
        </StyledFooterNavItem>
      </StyledFooterNavItems>

      <Stack
        direction="row"
        alignItems="center"
        sx={{ fontSize: 8, gap: "3px" }}
      >
        Copyright <AiOutlineCopyrightCircle size={12} /> 2022 - DOJ
      </Stack>
    </FooterContainer>
  );
};

export default Footer;
