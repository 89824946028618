import React from "react";
import { Oval } from "react-loader-spinner";
import { LoaderContainer } from "./style";

const Loader = ({ loading = false }) => {
  return loading ? (
    <LoaderContainer>
      <Oval
        ariaLabel="loading-indicator"
        height={100}
        width={100}
        strokeWidth={5}
        color="#fff"
        secondaryColor="#ff5757"
      />
    </LoaderContainer>
  ) : null;
};

export default Loader;
