import React from "react";
import "./style.js";
import {
  AboutContainer,
  AboutLeft,
  HtmlWrapper,
  BodyWrapper,
  H1Wrapper,
  PWrapper,
  AboutHeadingOne,
  AboutHeadingTwo,
  AboutRight,
} from "./style";
import Loader from "../../components/loader/index.jsx";
import SEO from "../../layouts/SEO.jsx";

const HomePage = () => {
  const [text, setText] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const [loadedImage, setLoadedImage] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  React.useEffect(() => {
    const txt = "I am Daddy Omar Jeng - FullStack Web and Mobile App Developer -";
    setText(
      txt
        .split("")
        .map((char, i) => `<span style="transform:rotate(${i * 5.7}deg)">${char}</span>`)
        .join("")
    );
    // console.log(text);
    return () => {};
  }, [text]);
  return (
    <>
      <SEO
        title="About"
        description="About DOJ, About Daddy Omar Jeng. Official Website of Daddy Omar Jeng - DOJ, a fullstack software enginner with a lot of experience in the software development field."
        keywords="About DOJ, About Daddy Omar Jeng, About Omar Jeng, who is Daddy Omar Jeng"
        url="https://www.daddyomarjeng.com/about"
      />
      <Loader loading={loading} />
      <AboutContainer>
        <AboutLeft>
          <HtmlWrapper>
            <BodyWrapper>
              <H1Wrapper>
                <AboutHeadingOne>About Me</AboutHeadingOne>
              </H1Wrapper>
              <PWrapper>
                <AboutHeadingTwo>
                  My name is Omar Jeng, I am a full stack software developer. I have a BSc in
                  Information Systems, a HND in Computer Science and many more Diplomas/Certificates
                  in the IT field.
                  <br />
                  <br />
                  I love coding and solving problems. I love challenging work that provides me the
                  opportunity to increase and improve my technical skills and abilities.
                  <br />
                  <br />I am very determined, I try to practice everyday so that I can improve my
                  skils and abilities.
                </AboutHeadingTwo>
              </PWrapper>
            </BodyWrapper>
          </HtmlWrapper>
        </AboutLeft>
        <AboutRight>
          <div className="circle">
            <Loader loading={!loadedImage} />

            <img
              src={require("../../assets/1.jpg")}
              alt="DOJ PROFILE"
              style={{
                position: "absolute",
                /* left: -10%; */
                height: "300px",
                width: "300px",
                borderRadius: "50%",
                /* background: url(../images/3.jpg); */
                // backgroundSize: "cover",
                objectFit: "cover",
                filter: "brightness(1.5) contrast(1.5)",
              }}
              onLoad={() => setLoadedImage(true)}
            />
            <div className="text">
              <p dangerouslySetInnerHTML={{ __html: text }}></p>
            </div>
          </div>
        </AboutRight>
      </AboutContainer>
    </>
  );
};

export default HomePage;
