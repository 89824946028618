export const URL = "https://www.daddyomarjeng.com";

export const PROJECTS = [
  {
    id: "0001",
    img: require("./assets/health-Pal.png"),
    link: "https://dojtech.live/index.php",
    code: "https://github.com/daddyomarjeng",
    name: "Health Pal Mobile App",
    description: "Schedule appointments with your preferred doctors easily and quickly.",
    tags: ["react native", "react", "javascript", "typescript"],
  },
  {
    id: "0002",
    img: require("./assets/zax.png"),
    link: "https://play.google.com/store/apps/details?id=apps.doj.Zax",
    code: "https://github.com/daddyomarjeng",
    name: "Zax Technologies Medical App",
    description:
      "Zax Technologies App is a powerful tool designed to enhance the efficiency and convenience of medical practitioners, specifically doctors. It seamlessly connects to Zax CRM, enabling doctors to manage their schedules and streamline their patient-related tasks.",
    tags: ["react native", "react", "javascript", "typescript"],
  },
  {
    id: 1,
    img: require("./assets/covid-website.png"),
    link: "https://dojtech.live/index.php",
    code: "https://github.com/daddyomarjeng/php_blog_website",
    name: "Covid-19 Tracker",
    description:
      "A Covid19 tracker built with HTML, CSS, Bootstrap and Python with flask as the backend.",
    tags: ["html", "css", "python", "flask", "bootstrap"],
  },
  {
    id: 2,
    img: require("./assets/php_blog.png"),
    link: "https://dojtech.live/index.php",
    code: "https://github.com/daddyomarjeng/php_blog_website",
    name: "PHP Blog with Admin Panel",
    description: "A blogging website with Admin Panel built with HTML, CSS, Bootstrap, and PHP",
    tags: ["html", "css", "bootstrap", "php"],
  },
  {
    id: 3,
    img: require("./assets/res.png"),
    link: "https://dojtech.live/index.php",
    code: "https://github.com/daddyomarjeng/php_blog_website",
    name: "Restaurant Billing System",
    description: "A Java Swing Restaurant billing System with Sqlite as the Database",
    tags: ["java"],
  },
  {
    id: 4,
    img: require("./assets/rnlogin.png"),
    link: "https://dojtech.live/index.php",
    code: "https://github.com/daddyomarjeng/php_blog_website",
    name: "Mobile Login and Registration UI",
    description: "A React Native Login and Registration UI with Loading Indication",
    tags: ["react native", "javascript"],
  },
  {
    id: 5,
    img: require("./assets/dict.png"),
    link: "https://dojtech.live/index.php",
    code: "https://github.com/daddyomarjeng/php_blog_website",
    name: "Python Web Dictionary",
    description: "A Web Dictionary built with Python and Flask",
    tags: ["html", "css", "javascript", "python", "flask", "bootstrap"],
  },
  {
    id: 6,
    img: require("./assets/weather.png"),
    link: "https://dojtech.live/index.php",
    code: "https://github.com/daddyomarjeng/php_blog_website",
    name: "Weather App",
    description: "A Weather application, built with Python and flask and Open weather API",
    tags: ["html", "css", "python", "flask", "bootstrap"],
  },
];

export const STACKS = [
  { id: 0, name: "All" },
  { id: 2, name: "Javascript" },
  { id: 3, name: "Typescript" },
  { id: 4, name: "React" },
  { id: 5, name: "React Native" },
  { id: 1, name: "Node" },
  { id: 6, name: "PHP" },
  { id: 7, name: "Java" },
  { id: 8, name: "Python" },
  { id: 9, name: "Bootstrap" },
];
export const TAGS = [
  // { id: 0, name: "All" },
  { id: 3, name: "javascript" },
  { id: 4, name: "react" },
  { id: 5, name: "react-native" },
  { id: 1, name: "node" },
  { id: 6, name: "php" },
  { id: 7, name: "java" },
  { id: 8, name: "python" },
  { id: 9, name: "bootstrap" },
];

export const POSTS = [
  // {
  //   id: 4,
  //   title: "Basics of Express Js",
  //   slug: "basics_of_express_js",
  //   tags: ["express", "javascript", "node"],
  //   image: "",
  //   content: "",
  //   date: "July-20-2022",
  // },
  {
    id: 4,
    title: "Java Number Guesser Game",
    slug: "java_number_guessed_game",
    tags: ["java"],
    image: "",
    // content: "",
    description:
      "A simple java number guesser program. A simple and straight to the point guide on how to use Java programming language to write a program that will ask the user to guess a number from 1 to 20. And it will assists the user by indicating weather number entered in higher or lower than the actual number.",
    keywords: "java, java number guesser, number guesser",
    date: "July-25-2022",
  },
  {
    id: 3,
    title: "Basics of Express Js",
    slug: "basics_of_express_js",
    tags: ["express", "javascript", "node"],
    image: "",
    // content: "",
    description: "Here you will learn the basics of Express Js from scratch",
    keywords: "express, javascript, node",
    date: "July-23-2022",
  },
  {
    id: 2,
    title: "MUI for Beginners",
    slug: "mui_for_beginners",
    tags: ["mui", "javascript", "react"],
    image: "",
    // content: "expres_js_basics.md",
    description: "Here you will learn the basics of MUI from scratch",
    keywords: "mui, javascript, react",
    date: "July-01-2022",
  },
  {
    id: 1,
    title: "Introduction To Gatsby Js",
    slug: "learn_gatsby",
    tags: ["express", "javascript", "node"],
    image: "",
    // content: getMd("learn_gatsby.md"),
    description: "Here you will learn the basics of Gatsby Js from scratch",
    keywords: "gatsby, javascript, react",
    date: "June-20-2022",
  },
];

// async function getMd(file) {
//   try {
//     const data = await require(`./posts/${file}`);

//     const data2 = await fetch(data.default);
//     const data3 = await data2.text();
//     const data4 = await data3;

//     console.log(data3);
//     return data4;
//   } catch (error) {
//     // console.log(error);
//   }
// }
