import { styled, Stack } from "@mui/material";

export const RightbarContainer = styled(Stack)(({ theme }) => ({
  // backgroundColor: "red",
  padding: "10px",
  height: "70vh",
  maxHeight: "70vh",
  overflow: "auto",
  position: "sticky",
  top: 100,
  gap: 20,
  //   borderLeft: "2px solid #ccc",
  [theme.breakpoints.down("sm")]: {
    // display: "none",
  },
}));
